<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedZipActions"
        :default-value="properties.action"
        step-label="zip"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <v-col v-if="properties.action === 'COMPRESS'" cols="12" class="pt-1 pb-2">
      <zip-entries
        :key="`${step.id}-entries`"
        :can-edit="canEdit"
        :data="properties.fields.entries"
        @dataChanged="handleChange('fields.entries', $event)"
      />
    </v-col>

    <v-col v-if="properties.action === 'DECOMPRESS'" cols="12">
      <v-text-field
        :key="`${step.id}-sourceObject`"
        v-model="properties.fields.sourceObject"
        outlined
        dense
        class="required-asterisk"
        data-cy="zip-sourceObject"
        :label="$lang.labels.sourceObject"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        class="required-asterisk"
        data-cy="zip-targetObject"
        :label="$lang.labels.targetObject"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
      />
    </v-col>
  </v-row>
</template>

<script>
import ZipEntries from '../ZipEntriesModal.vue'
import { ActionSelect } from './components'
import StepMixin from './StepMixin'

import { ZIP_ACTIONS } from './constants'
import { getNestedPropertyForSetting } from '@/utils/helpers'

export default {
  name: 'ZipStep',
  components: {
    ZipEntries,
    ActionSelect
  },
  mixins: [StepMixin],
  computed: {
    formattedZipActions() {
      return ZIP_ACTIONS.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  created() {
    if (!this.properties.action) this.properties.action = 'COMPRESS'
    if (!this.properties.fields) this.properties.fields = {}
  },
  methods: {
    handleChange(key, val) {
      if (key === 'action') {
        this.onChangeZipAction(val)
      }
      if (key.includes('.')) {
        const { nestedObj, lastKey } = getNestedPropertyForSetting(this.properties, key)

        this.$set(nestedObj, lastKey, val)

        return
      }

      this.$set(this.properties, key, val)
    },
    onChangeZipAction(val) {
      if (val === 'COMPRESS') {
        this.properties.fields = {
          entries: []
        }
      }
      if (val === 'DECOMPRESS ') {
        this.properties.fields = {
          sourceObject: ''
        }
      }
    }
  }
}
</script>
